import React from 'react';

const redirectUrl: string | undefined = process.env.REACT_APP_REDIRECT_URL;

const isEnvValid = !!redirectUrl;

if (!isEnvValid) {
  throw new Error('Missing environment variables.');
}

const dotenv = {
  redirectUrl,
};

export const EnvContext = React.createContext<typeof dotenv>(dotenv);

export const useEnv = () => {
  const context = React.useContext(EnvContext);
  if (!context) {
    throw new Error(`useEnv must be used within a EnvProvider`);
  }
  return context;
};

type EnvProps = {
  children: React.ReactNode; // 👈️ type children
};

export const EnvProvider: React.FC<EnvProps> = (props: EnvProps) => {
  return (
    <EnvContext.Provider value={dotenv} {...props}>
      {props.children}
    </EnvContext.Provider>
  );
};
