import React from 'react';
import { useEnv } from './context/Env';

export const App: React.FC = () => {
  const { redirectUrl } = useEnv();

  // console.log(window.location);
  const redirectTarget = redirectUrl + window.location.pathname;
  console.log(`Redirecting to: ${redirectTarget}`);
  window.location.replace(redirectTarget);
  return (
    <div></div>
  );
};
