import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
import { EnvProvider } from './context/Env/env.context';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <EnvProvider>
      <App />
    </EnvProvider>
  </React.StrictMode>
);
